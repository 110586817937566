body {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #333;
  background-image: url(.././public/pes\ k.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  animation: backgroundAnimation 1s infinite alternate;
}

@keyframes backgroundAnimation {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}

.App {
  text-align: center;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

h1 {
  font-size: 24px;
}

input {
  padding: 10px;
  font-size: 18px;
  margin-top: 10px;
  
  transform-origin: center;
}
.shake{
  animation: shake 0.5s infinite alternate;
}

@keyframes shake {
  0% {
    transform: translateX(0) rotate(0deg);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-50px) rotate(-20deg);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(50px) rotate(20deg);
  }
}
